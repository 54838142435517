import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Quiz.css"; // Ensure this is the correct path to your CSS file
import { useCookies } from "react-cookie";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function Quiz() {
  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";
  const navigate = useNavigate(); // Hook to get the navigate function

  useEffect(() => {
    const handlePopState = () => {
      window.history.go(1);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  // Function to handle click event
  const navigateToVideo = () => {
    navigate("/home"); // Navigate to /video route
  };

  const [cookies, removeCookie] = useCookies([]);
  const [selectedOptions, setSelectedOptions] = useState({
    q1: {
      Label:
        "What shell command would you run to exploit a sql_injection vulnerability on System0?",
      Answer: "",
    },
    q2: {
      Label:
        "What shell command will show the folders or files listed in a directory?",
      Answer: "",
    },
    q3: {
      Label:
        "What shell command will copy the contents of the folder in location '/home/projects' to the current file location?",
      Answer: "",
    },
    q4: {
      Label:
        "What shell command will allow you to read the contents of README.txt file?",
      Answer: "",
    },
    q5: {
      Label:
        "What shell command will make you enter the “home” folder?",
      Answer: "",
    },
    // Add other questions similarly if necessary
  });
  const [answeredQuestions, setAnsweredQuestions] = useState({}); // Add this line
  const [user, setUsername] = useState("");

  const correctAnswers = {
    q1: "use_exploit sql_injection System0",
    q2: "ls",
    q3: "cp /home/projects ./",
    q4: "cat README.txt",
    q5: "cd home",
    // Add correct answers for other questions here
  };

  useEffect(() => {
    const verifyCookie = async () => {
      try {
        const { data } = await axios.post(
          `${apiUrl}`,
          {},
          { withCredentials: true }
        );

        const { status, user } = data;
        console.log("Username from server:", user);
        if (status) {
          setUsername(user);
          toast(`Hello ${user}`, {
            position: "top-right",
          });
        } else {
          removeCookie("token");
          navigate("/login");
        }
      } catch (error) {
        console.error("Verification failed:", error);
        removeCookie("token");
        navigate("/login");
      }
    };

    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  const handleOptionChange = (questionId, optionValue) => {
    if (answeredQuestions[questionId]) return; // Prevent changes if question is already answered

    setSelectedOptions((prev) => ({
      ...prev,
      [questionId]: {
        ...prev[questionId],
        Answer: optionValue,
      },
    }));
  };

  const renderFeedback = (questionId) => {
    if (!selectedOptions[questionId].Answer) return null; // Only show feedback if an answer is selected
    const isCorrect =
      selectedOptions[questionId].Answer === correctAnswers[questionId];
    const feedbackText = isCorrect
      ? "Correct!"
      : `Wrong Answer. The correct answer is: "${correctAnswers[questionId]}"`;

    return (
      <div
        className={`quiz-feedback ${isCorrect ? "correct-answer" : "wrong-answer"
          }`}
      >
        {feedbackText}
      </div>
    );
  };

  // Keeping track of errors to form
  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });

  // Success notifier
  const handleSuccess = (msg) => {
    toast.success(msg, {
      position: "bottom-left",
    });
    setTimeout(() => {
      navigate("/home"); // Adjust the redirect as needed
    }, 2000); // Adjust timing as needed
  };

  let date = new Date();

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    const additionDate = {
      createdDate: String(date.toLocaleDateString('en-US', { timeZone: 'America/Denver' })),
      createdTime: String(date.toLocaleTimeString('en-US', { timeZone: 'America/Denver' })),
    };

    const payload = {
      username: user, // Include username in the payload
      ...selectedOptions,
      ...additionDate,
    };

    try {
      const { data } = await axios.post(`${apiUrl}/protocolquiz`, payload, {
        withCredentials: true,
      });
      const { success, message } = data;
      if (success) {
        handleSuccess(message);
      } else {
        handleError(message);
      }
    } catch (error) {
      console.log(error);
      handleError("An error occurred while submitting the quiz.");
    }
  };

  const renderOptions = (questionId) => {
    const options = {
      q1: [
        "use_exploit System0",
        "use_exploit System0 sql_injection",
        "use_exploit sql_injection System0",
        "use_exploit sql_injection",
      ],
      q2: ["list", "ls", "ls -a", "ll"],
      q3: [
        "cp /home/projects ./",
        "cp projects ./",
        "cp /home/projects./current_directory",
        "cp /home/projects",
      ],
      q4: [
        "view README.txt",
        "cat README",
        "cat README.txt",
        "ls README.txt",
      ],
      q5: ["cd home", "cd", "cd home folder", "enter home"],
    };

    return options[questionId].map((option) => (
      <div
        key={option}
        className={`quiz-option ${selectedOptions[questionId]?.Answer === option ? "selected" : ""
          }`}
        onClick={() => handleOptionChange(questionId, option)}
      >
        {option}
      </div>
    ));
  };

  return (
    <div className="quiz-container">
      <h2>Instructions Comprehension Test</h2>

      {/* Question 1 */}
      <div className="quiz-question">
        <p>{selectedOptions.q1.Label}</p>
        {renderOptions("q1")}
        {renderFeedback("q1")}
      </div>

      {/* Question 2 */}
      <div className="quiz-question">
        <p>{selectedOptions.q2.Label}</p>
        {renderOptions("q2")}
        {renderFeedback("q2")}
      </div>

      {/* Question 3 */}
      <div className="quiz-question">
        <p>{selectedOptions.q3.Label}</p>
        {renderOptions("q3")}
        {renderFeedback("q3")}
      </div>

      {/* Question 4 */}
      <div className="quiz-question">
        <p>{selectedOptions.q4.Label}</p>
        {renderOptions("q4")}
        {renderFeedback("q4")}
      </div>

      {/* Question 5 */}
      <div className="quiz-question">
        <p>{selectedOptions.q5.Label}</p>
        {renderOptions("q5")}
        {renderFeedback("q5")}
      </div>

      {/* Submit Button */}
      <button onClick={handleSubmit} className="cont_button">
        Continue
      </button>

      {/*<ToastContainer />*/}
    </div>
  );
}

export default Quiz;
