import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import "./Home.css";
import PracticeTaskQA from "./PracticeTaskQA";
import PracticeSystemVulnerabilities from "./PracticeSystemVulnerabilities";
import PracticeLinuxFolderStructure from "./PracticeLinuxFolderStructure";

const PracticeRound = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";

  const [cookies, removeCookie] = useCookies([]);
  const [username, setUsername] = useState("");

  useEffect(() => {
    const verifyCookie = async () => {
      /* if (!cookies.token) {
        navigate("/login");
      } */
      const { data } = await axios.post(
        `${apiUrl}`,
        {},
        { withCredentials: true }
      );
      const { status, user } = data;
      setUsername(user);
      return status
        ? console.log(`Hello ${user}`)
        : (removeCookie("token"), navigate("/login"));
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  const Logout = () => {
    removeCookie("token");
    navigate("/signup");
  };

  const PracticeGame = () => {
    navigate("/practicegame", {
      state: {
        lFolderStructure,
        taskQuestionSolution,
        systemVulnerabilitiesList,
      },
    });
  };

  const [lFolderStructure, setLFolderStructure] = useState({});
  const [taskQuestionSolution, setTaskQuestionSolution] = useState({});
  const [systemVulnerabilitiesList, setSystemVulnerabilitiesList] = useState(
    {}
  );

  const handleFolderData = (dataFromLinuxFolderStructure) => {
    setLFolderStructure(dataFromLinuxFolderStructure);
  };

  const handleTaskData = (dataFromTaskQA) => {
    setTaskQuestionSolution(dataFromTaskQA);
  };

  const handleVulnerabilitiesData = (dataFromSystemVulnerabilities) => {
    setSystemVulnerabilitiesList(dataFromSystemVulnerabilities);
  };

  return (
    <>
      <div className="home_page">
        <h4> Practice Round</h4>
        <PracticeLinuxFolderStructure autoAssignLFS={handleFolderData} />
        <PracticeTaskQA assignTaskQA={handleTaskData} />
        <PracticeSystemVulnerabilities
          assignVulnerabilities={handleVulnerabilitiesData}
        />
        <button onClick={PracticeGame}>Start Game</button>
      </div>
    </>
  );
};

export default PracticeRound;
