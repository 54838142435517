import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Assuming this is correct based on your environment
import "./Consent.css"; // Assuming this is correct based on your environment

function createSubTitle({ title, body, body2, body3, body4 }) {
  return (
    <div>
      <h3>{title}</h3>
      <div className="divider" />
      <br />
      <p>{body}</p>
      <br />
      {body2 && (
        <div>
          <p>{body2}</p>
          <br />
        </div>
      )}
      {body3 && (
        <div>
          <p>{body3}</p>
          <br />
        </div>
      )}
      {body4 && (
        <div>
          <p>{body4}</p>
          <br />
        </div>
      )}
    </div>
  );
}

function Consent() {
  const [ageConfirmation, setAgeConfirmation] = useState(false);
  const [understoodInformation, setUnderstoodInformation] = useState(false);
  const [wantsToParticipate, setWantsToParticipate] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ageConfirmation && understoodInformation && wantsToParticipate) {
      navigate("/signup");
    } else if(!ageConfirmation) {
      alert("You must be older than 18 to continue. Please close the tab if you do not match the criteria.");
    } else {
      alert("You must agree to all statements to continue");
    }
  };

  // Convert boolean state to a corresponding string for the select value
  const selectValueFor = (boolValue) => (boolValue ? "yes" : "no");

  return (
    <div className="consent_form">
      {" "}
      {/* This class should match your CSS styling */}
      <div className="form_container">
        {" "}
        {/* This div will get styled by .sign_up .form_container styles */}
        <form onSubmit={handleSubmit}>
          <h2>Informed Consent Form for Research Involving Human Subjects</h2>
          <br />
          <div className="divider" />
          <br />
          <h3>
            Protocol Title:{" "}
            <span style={{ fontWeight: "normal" }}>
              Decision Making in Cyber Situations
            </span>
          </h3>
          <br />
          <h3>
            Principal Investigator:{" "}
            <span style={{ fontWeight: "normal" }}>Dr. Palvi Aggarwal</span>
          </h3>
          <br />
          <h3>
            UTEP Department:{" "}
            <span style={{ fontWeight: "normal" }}>
              Department of Computer Science
            </span>
          </h3>
          <br />
          <div className="divider" />
          <p>
            In this consent form, “you” always means the study subject. If you
            are a legally authorized representative, please remember that “you”
            refers to the study subject.
          </p>
          <div className="divider" />
          <br />

          {createSubTitle({
            title: "Introduction",
            body: "You are being asked to take part voluntarily in the research project described below. You are encouraged to take your time in making your decision. It is important that you read the information that describes the study. Please ask the study researcher or the study staff to explain any words or information that you do not clearly understand.",
          })}

          {createSubTitle({
            title: "Why is this study being done?",
            body: "This task is part of a research study conducted by Dr. Palvi Aggarwal at the University of Texas at El Paso. The purpose of this research is to understand the decision-making factors in network exploitation scenario.",
            body2:
              "Approximately, 50 participants per study, will be enrolling in this study through online advertisements.",
            body3:
              "You are being asked to be in the study because you have expressed interest in participating in this research and possess fundamental knowledge in the field of cybersecurity. In addition, you must be over the age of 18 to participate.",
            body4:
              "If you decide to enroll in this study, your involvement will last about 30 to 60 minutes.",
          })}

          {createSubTitle({
            title: "What is involved in the study?",
            body: "If you agree to take part in this study, you will engage in a data exfiltration scenario within the experimental testbed. Research team will request your demographic information, assess your personality traits, and administer cybersecurity awareness and knowledge questionnaires. Your performance will be gauged based on points accrued throughout the experiment. These points will subsequently be translated into your total earnings. The cumulative points attained signify the rewards attributed to making precise decisions.",
          })}

          {createSubTitle({
            title: "What are the risks and discomforts of this study?",
            body: "The risks associated with this research are no greater than those involved in daily activities.",
          })}

          {createSubTitle({
            title:
              "What will happen if I am injured in this study? Required for greater than minimal risk",
            body: "The University of Texas at El Paso and its affiliates do not offer to pay for or cover the cost of medical treatment for research related illness or injury. No funds have been set aside to pay or reimburse you in the event of such injury or illness. You will not give up any of your legal rights by signing this consent form. You should report any such injury to Dr. Palvi Aggarwal (915-747-6399) and to the UTEP Institutional Review Board (IRB) at (915-747-6590) or irb.orsp@utep.edu.",
          })}

          {createSubTitle({
            title: "Are there benefits to taking part in this study?",
            body: "You are not likely to benefit by taking part in this study. This research may help us to understand the decision-making patterns in Cybersecurity scenarios.",
          })}

          {createSubTitle({
            title:
              "Who is paying for this study? Optional-If not funded omit item.",
            body: "Internal Funding:",
            body2:
              "The startup funds provided to Dr. Palvi Aggarwal by the UTEP Department of Computer Science will offer financial support for conducting this study.",
          })}

          {createSubTitle({
            title: "What are my costs?",
            body: "There are no direct costs.",
          })}

          {createSubTitle({
            title: "Will I be paid to participate in this study?",
            body: "If payment:",
            body2:
              "You will receive a compensation of $12 per hour as a gift card for your participation in the study. Furthermore, based on your performance within the study, you have the potential to earn an additional sum ranging from $10 to $15 for the whole task. To be eligible for compensation, it's necessary to complete the entire study, including the demographics survey, main task, and post-task questionnaire. Please note that partial completion does not qualify for compensation. You will not be penalized if you choose to withdraw from the study without completing it, but you will not be compensated either. There will be no cost to you if you participate in this study.",
          })}

          {createSubTitle({
            title: "What other options are there?",
            body: "You have the option not to take part in this study. If at any point you do not feel comfortable with this or any other aspect of the study, you can stop at any time. There will be no penalties involved if you choose not to take part in or withdraw from this study. Choosing to withdraw or not participate will not affect your grades, class, or university standing.",
          })}

          {createSubTitle({
            title:
              "What if I want to withdraw, or am asked to withdraw from this study?",
            body: "Taking part in this study is voluntary. You have the right to choose not to take part in this study. If you do not take part in the study, there will be no penalty or loss of benefit.",
            body2:
              "If you choose to take part, you have the right to skip any questions or stop at any time. However, we encourage you to talk to a member of the research group so that they know why you are leaving the study.",
          })}

          {createSubTitle({
            title: "Who do I call if I have questions or problems?",
            body: "You may ask any questions you have now. If you have questions later, you may email Dr. Palvi Aggarwal (915-747-6399) at paggarwal@utep.edu.",
            body2:
              "If you have questions or concerns about your participation as a research subject, please contact the UTEP Institutional Review Board (IRB) at (915) -747-6590 or irb.orsp@utep.edu.",
          })}

          {createSubTitle({
            title: "What about confidentiality?",
            body: "Your part in this study is confidential. We will collect your email address for payment purpose. No other personal information will be collected as part of this study. The emails will be stored separately from the dataset to preserve confidentiality.",
          })}

          {createSubTitle({
            title: "Mandatory reporting",
            body: "If information is revealed about child abuse or neglect, or potentially dangerous future behavior to others, the law requires that this information be reported to the proper authorities.",
          })}

          {createSubTitle({
            title: "Authorization Statement",
            body: "I have read each page of this paper about the study (or it was read to me). I will be given a copy of the form to keep. I know I can stop being in this study without penalty. I know that being in this study is voluntary and I choose to be in this study.",
          })}

          <label>
            I am age 18 or older.
            <select
              value={selectValueFor(ageConfirmation)}
              onChange={(e) => setAgeConfirmation(e.target.value === "yes")}
            >
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </select>
          </label>
          <br />

          <label>
            I have read and understood the information above.
            <select
              value={selectValueFor(understoodInformation)}
              onChange={(e) =>
                setUnderstoodInformation(e.target.value === "yes")
              }
            >
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </select>
          </label>
          <br />

          <label>
            I want to participate in this research and continue with the game.
            <select
              value={selectValueFor(wantsToParticipate)}
              onChange={(e) => setWantsToParticipate(e.target.value === "yes")}
            >
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </select>
          </label>
          <br />

          <button type="submit" className="cont_button">
            Continue
          </button>
        </form>
      </div>
    </div>
  );
}

export default Consent;
