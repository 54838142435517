import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import "./Demographics.css"; // Ensure you have appropriate CSS for this form

const Demographics = () => {
  const [cookies, removeCookie] = useCookies([]);
  const [user, setUsername] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";

  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState({
    age: "",
    country: "",
    gender: "",
    educationLevel: "",
    major: "",
    hasTakenCourse: "", // For computer networks or security course
    username: user, // Add a field for username
  });

  useEffect(() => {
    const handlePopState = () => {
      window.history.go(1);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    console.log("USEEFFECT!");
    const verifyCookie = async () => {
      //this is redirecting to login page if there is no token
      /*
      if (!cookies.token) {
        navigate("/login");
      }
      */
      const { data } = await axios.post(
        `${apiUrl}`,
        {},
        { withCredentials: true }
      );
      const { status, user } = data;
      setUsername(user);

      // Update inputValue state to include the username
      setInputValue((currentValues) => ({
        ...currentValues,
        username: user, // Set the username here
      }));

      return status
        ? toast(`Hello ${user}`, {
            position: "top-right",
          })
        : (removeCookie("token"), navigate("/login"));
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });

  const handleSuccess = (msg) => {
    toast.success(msg, {
      position: "bottom-left",
    });
    setTimeout(() => {
      navigate("/instructions"); // Adjust the redirect as needed
    }, 2000); // Adjust timing as needed
  };

  let date = new Date();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const additionDate = {
      createdDate : String(date.toLocaleDateString('en-US', {timeZone: 'America/Denver'})),
      createdTime : String(date.toLocaleTimeString('en-US', {timeZone: 'America/Denver'})),
    };
    try {
      const { data } = await axios.post(
        `${apiUrl}/demographics`, // Adjust the endpoint as needed
        { ...inputValue,
          ...additionDate
         },
        { withCredentials: true }
      );
      const { success, message } = data;
      if (success) {
        handleSuccess(message);
      } else {
        handleError(message);
      }
    } catch (error) {
      console.log(error);
      handleError("An error occurred while submitting the form.");
    }
  };

  // You might need to add a display or use the username in some part of your form
  // For now, the username is simply stored in the state

  return (
    <div className="demographic_form">
      <div className="form_container">
        <h2>Demographics</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="age">Age</label>
            <select
              name="age"
              value={inputValue.age}
              onChange={handleOnChange}
            >
              <option value="">Select your age</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
              <option value="32">32</option>
              <option value="33">33</option>
              <option value="34">34</option>
              <option value="35">35</option>
              <option value="36">36</option>
              <option value="37">37</option>
              <option value="38">38</option>
              <option value="39">39</option>
              <option value="40">40</option>
              <option value="41">41</option>
              <option value="42">42</option>
              <option value="43">43</option>
              <option value="44">44</option>
              <option value="45">45</option>
              <option value="46">46</option>
              <option value="47">47</option>
              <option value="48">48</option>
              <option value="49">49</option>
              <option value="50">50</option>
              <option value="51">51</option>
              <option value="52">52</option>
              <option value="53">53</option>
              <option value="54">54</option>
              <option value="55">55</option>
              <option value="56">56</option>
              <option value="57">57</option>
              <option value="58">58</option>
              <option value="59">59</option>
              <option value="60">60</option>
              <option value="61">61</option>
              <option value="62">62</option>
              <option value="63">63</option>
              <option value="64">64</option>
              <option value="65+">65+</option>
            </select>
          </div>
          <div>
            <label htmlFor="country">Country</label>
            <input
              type="text"
              name="country"
              value={inputValue.country}
              placeholder="Enter your country"
              onChange={handleOnChange}
            />
          </div>
          <div>
            <label htmlFor="gender">Gender</label>
            <select
              name="gender"
              value={inputValue.gender}
              onChange={handleOnChange}
            >
              <option value="">Select your gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div>
            <label htmlFor="educationLevel">Education Level</label>
            <select
              name="educationLevel"
              value={inputValue.educationLevel}
              onChange={handleOnChange}
            >
              <option value="">Select your education level</option>
              <option value="highSchool">High School</option>
              <option value="bachelors">Bachelor's</option>
              <option value="masters">Master's</option>
              <option value="phd">PhD</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div>
            <label htmlFor="major">Major</label>
            <input
              type="text"
              name="major"
              value={inputValue.major}
              placeholder="Enter your major"
              onChange={handleOnChange}
            />
          </div>
          <div>
            <label htmlFor="hasTakenCourse">
              Have you ever taken a course in Computer Networks or Computer
              Security?
            </label>
            <select
              name="hasTakenCourse"
              value={inputValue.hasTakenCourse}
              onChange={handleOnChange}
            >
              <option value="">Select an option</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <button type="submit" className="cont_button">
            Continue
          </button>
        </form>
        {/*<ToastContainer />*/}
      </div>
    </div>
  );
};

export default Demographics;
