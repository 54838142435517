import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import "./ExperienceQuiz.css"; // Ensure you have appropriate CSS for this form

function createCheckboxInput({ name, options, onChange }) {
  //Component Return
  return (
    <div>
      <label className="qLabel" htmlFor={name.name}>
        {name.label}
      </label>
      {options.map((option, index) => (
        <div>
          <input
            style={{ width: "25px" }}
            type="checkbox"
            name={option.name ? option.name : name.name}
            id={option.id}
            value={option.value}
            onChange={() => onChange(name.name, name.label, option.label)}
          />
          <label className="aLabel" htmlFor={option.id}>
            {option.label}
          </label>
          <br />
        </div>
      ))}
      <br />
    </div>
  );
}

function createRadioInput({ name, options, onChange }) {
  return (
    <div>
      <label className="qLabel" htmlFor={name.name}>
        {name.label}
      </label>
      {options.map((option, index) => (
        <div>
          <input
            style={{ width: "25px" }}
            type="radio"
            name={name.name}
            id={option.id}
            value={option.value}
            onChange={() => onChange(name.name, name.label, option.label)}
            required
          />
          <label className="aLabel" htmlFor={option.id}>
            {option.label}
          </label>
          <br />
        </div>
      ))}
      <br />
    </div>
  );
}

function createNumberInput({ name, option, onChange }) {
  return (
    <div>
      <label className="qLabel" htmlFor={name.name}>
        {name.label}
      </label>
      <input
        type="number"
        name={name.name}
        id={option.id}
        value={option.value}
        placeholder={option.placeholder}
        onChange={() => onChange(name.name, name.label, option.label)}
        required
      />
      <br />
      <br />
    </div>
  );
}

function createTextInput({ name, option, onChange }) {
  return (
    <div>
      <label className="qLabel" htmlFor={name.name}>
        {name.label}
      </label>
      <input
        type="text"
        name={name.name}
        id={option.id}
        value={option.value}
        placeholder={option.placeholder}
        onChange={(e) => onChange(name.name, name.label, e.target.value)}
      />
      <br />
      <br />
    </div>
  );
}

const ExperienceQuiz = () => {
  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";
  // Navigate for routing to different pages
  const navigate = useNavigate();

  // Array for keeping track of answered questions
  const [cookies, removeCookie] = useCookies([]);
  const [inputValue, setInputValue] = useState({});
  const [user, setUsername] = useState("");

  useEffect(() => {
    const handlePopState = () => {
      window.history.go(1);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  // Verify and Save Username
  useEffect(() => {
    const verifyCookie = async () => {
      //this is redirecting to login page if there is no token
      const { data } = await axios.post(
        `${apiUrl}`,
        {},
        { withCredentials: true }
      );
      const { status, user } = data;
      setUsername(user);

      // Update inputValue state to include the username
      setInputValue({
        ...inputValue,
        username: user, // Set the username here
      });

      return status
        ? toast(`Hello ${user}`, {
            position: "top-right",
          })
        : (removeCookie("token"), navigate("/login"));
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  // Keeping track of changes to variables
  const handleOnChange = (qId, qLabel, aLabel) => {
    setInputValue({
      ...inputValue,
      [qId]: {
        Label: qLabel,
        Answer: [aLabel],
      },
    });
  };

  // Keeping track of errors to form
  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });

  // Success notifier
  const handleSuccess = (msg) => {
    toast.success(msg, {
      position: "bottom-left",
    });
    setTimeout(() => {
      navigate("/thankyoupoint"); // Adjust the redirect as needed
    }, 2000); // Adjust timing as needed
  };

  let date = new Date();

  // Submit the form to back-end on Success
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const additionDate = {
        createdDate: String(date.toLocaleDateString('en-US', { timeZone: 'America/Denver' })),
        createdTime: String(date.toLocaleTimeString('en-US', { timeZone: 'America/Denver' })),
      };

      const { data } = await axios.post(
        `${apiUrl}/experiencequiz`, // Adjust the endpoint as needed
        { ...inputValue,
          ...additionDate
         },
        { withCredentials: true }
      );
      const { success, message } = data;
      if (success) {
        handleSuccess(message);
      } else {
        handleError(message);
      }
    } catch (error) {
      console.log(error);
      handleError("An error occurred while submitting the form.");
    }
  };

  /* Custom value storage for checkbox components */
  const innerCheckboxChange = (qId, qLabel, aLabel) => {
    if (
      inputValue[qId] &&
      inputValue[qId].Answer &&
      inputValue[qId].Answer.includes(aLabel)
    ) {
      //Remove it
      setInputValue((prevInputValue) => ({
        ...prevInputValue,
        [qId]: {
          Label: qLabel,
          Answer: prevInputValue[qId].Answer.filter(
            (answer) => answer !== aLabel
          ),
        },
      }));
    } else {
      //Create and/or add to it
      setInputValue((prevInputValue) => ({
        ...prevInputValue,
        [qId]: {
          Label: qLabel,
          Answer: prevInputValue[qId]
            ? [...prevInputValue[qId].Answer, aLabel]
            : [aLabel],
        },
      }));
    }
  };

  // User Interface Components
  return (
    <div className="kqsurvey_form">
      <div className="kqform_container">
        <h2 style={{ textAlign: "center", fontSize: "2rem" }}>
          Experience Quiz
        </h2>
        <h3 style={{ color: "#333", fontSize: "1.2rem" }}>
          We are interested in learning more about your experience in the field
          related to our study. Please answer the following questions based on
          your professional experience.
        </h3>
        <form onSubmit={handleSubmit}>
          {/* ================================================================================= */}
          {/* ================================================================================= */}
          {/* ================================================================================= */}
          {/* ================================================================================= */}
          {/* ================================================================================= */}

          {/* Question Time */}
          {createRadioInput({
            name: {
              name: "q1",
              label:
                "How many years of working experience do you have in network operation and security area?",
            },
            options: [
              { label: "10+ years", value: "q1-1", id: "q1-1" },
              { label: "5-10 years", value: "q1-2", id: "q1-2" },
              { label: "1-5 years", value: "q1-3", id: "q1-3" },
              {
                label: "A few months (less than a year)",
                value: "q1-4",
                id: "q1-4",
              },
              { label: "None", value: "q1-5", id: "q1-5" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q2",
              label:
                "What is your highest level of degree qualification in topics related to network operation and security?",
            },
            options: [
              { label: "Doctoral", value: "q2-1", id: "q2-1" },
              { label: "Master's", value: "q2-2", id: "q2-2" },
              { label: "Bachelor's", value: "q2-3", id: "q2-3" },
              { label: "Associate/Certificate", value: "q2-4", id: "q2-4" },
              { label: "None", value: "q2-5", id: "q2-5" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q3",
              label:
                "What defines best your practical experience in network operation and security?",
            },
            options: [
              { label: "I am an expert", value: "q3-1", id: "q3-1" },
              {
                label: "I have lots of hands on experience",
                value: "q3-2",
                id: "q3-2",
              },
              {
                label: "I have some hands on experience",
                value: "q3-3",
                id: "q3-3",
              },
              {
                label: "I have little hands on experience",
                value: "q3-4",
                id: "q3-4",
              },
              {
                label: "I have no experience at all",
                value: "q3-5",
                id: "q3-5",
              },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q4",
              label:
                "How many courses/technical training you have taken in network operation and security?",
            },
            options: [
              { label: "10+ years", value: "q4-1", id: "q4-1" },
              { label: "5-10 years", value: "q4-2", id: "q4-2" },
              { label: "1-5 years", value: "q4-3", id: "q4-3" },
              {
                label: "A few months (less than a year)",
                value: "q4-4",
                id: "q4-4",
              },
              { label: "None", value: "q4-5", id: "q4-5" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q5",
              label:
                "On average, how many hours in the day do you handle issues related to network operations and security?",
            },
            options: [
              { label: "8+ hours", value: "q5-1", id: "q5-1" },
              { label: "4-8 hours", value: "q5-2", id: "q5-2" },
              { label: "1-4 hours", value: "q5-3", id: "q5-3" },
              { label: "less than 1 hour", value: "q5-4", id: "q5-4" },
              { label: "0", value: "q5-5", id: "q5-5" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q6",
              label: "On average, how many hours a day do you use a computer?",
            },
            options: [
              { label: "8+ hours", value: "q6-1", id: "q6-1" },
              { label: "4-8 hours", value: "q6-2", id: "q6-2" },
              { label: "1-4 hours", value: "q6-3", id: "q6-3" },
              { label: "less than 1 hour", value: "q6-4", id: "q6-4" },
              { label: "0", value: "q6-5", id: "q6-5" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q7",
              label:
                "On average, how many times do you have to deal with computer security related problems?",
            },
            options: [
              { label: "Several times a day", value: "q7-1", id: "q7-1" },
              { label: "Once Every day", value: "q7-2", id: "q7-2" },
              { label: "Once Every week", value: "q7-3", id: "q7-3" },
              { label: "Once Every month", value: "q7-4", id: "q7-4" },
              { label: "Once Every year or less", value: "q7-5", id: "q7-5" },
            ],
            onChange: handleOnChange,
          })}

          {createRadioInput({
            name: {
              name: "q8",
              label:
                "How many computer programming languages do you know (excluding HTML)?",
            },
            options: [
              { label: "10+", value: "q8-1", id: "q8-1" },
              { label: "5-10", value: "q8-2", id: "q8-2" },
              { label: "2-5", value: "q8-3", id: "q8-3" },
              { label: "1", value: "q8-4", id: "q8-4" },
              { label: "None", value: "q8-5", id: "q8-5" },
            ],
            onChange: handleOnChange,
          })}

          {createCheckboxInput({
            name: {
              name: "q9",
              label:
                "What information and network security tools do you use regularly? (Please mark all those that apply)",
            },
            options: [
              { label: "Firewall", value: "q9-1", id: "q9-1", name: "q9" },
              { label: "Anti-virus", value: "q9-2", id: "q9-2", name: "q9" },
              {
                label: "Intrusion Detection System (IDS)",
                value: "q9-3",
                id: "q9-3",
                name: "q9",
              },
              {
                label: "Secure Shell (SSH)",
                value: "q9-4",
                id: "q9-4",
                name: "q9",
              },
              {
                label: "Pretty Good Privacy (PGP)",
                value: "q9-5",
                id: "q9-5",
                name: "q9",
              },
              {
                label: "Access Control",
                value: "q9-6",
                id: "q9-6",
                name: "q9",
              },
            ],
            onChange: innerCheckboxChange,
          })}

          {createRadioInput({
            name: {
              name: "q10",
              label:
                "How often have you worked with an intrusion detection system?",
            },
            options: [
              { label: "Several times a day", value: "q10-1", id: "q10-1" },
              { label: "Once Every day", value: "q10-2", id: "q10-2" },
              { label: "Once Every week", value: "q10-3", id: "q10-3" },
              { label: "Once Every month", value: "q10-4", id: "q10-4" },
              { label: "Once Every year or less", value: "q10-5", id: "q10-5" },
            ],
            onChange: handleOnChange,
          })}

          {createCheckboxInput({
            name: {
              name: "q11",
              label:
                "Which of the following deception techniques do you use regularly? (Please mark all those that apply)",
            },
            options: [
              { label: "Masking", value: "q11-1", id: "q11-1", name: "q11-1" },
              { label: "Decoying", value: "q11-2", id: "q11-2", name: "q11-2" },
              {
                label: "Mimicking",
                value: "q11-3",
                id: "q11-3",
                name: "q11-3",
              },
              {
                label: "Signaling",
                value: "q11-4",
                id: "q11-4",
                name: "q11-4",
              },
            ],
            onChange: innerCheckboxChange,
          })}
          <button type="submit" className="cont_button">
            Continue
          </button>
        </form>
        {/*<ToastContainer />*/}
      </div>
    </div>
  );
};

export default ExperienceQuiz;
