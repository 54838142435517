import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import "./ThankYou.css"; // Ensure you have appropriate CSS for this form

const ThankYou = () => {
  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";
  // Navigate for routing to different pages
  const navigate = useNavigate();

  // Array for keeping track of answered questions
  const [cookies, removeCookie] = useCookies([]);
  const [username, setUsername] = useState("");
  const [totPoints, setTotalPoints] = useState(0);

  useEffect(() => {
    const handlePopState = () => {
      window.history.go(1);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    const getTotalPoints = async (user) => {
      try {
        console.log("Sending username:", user);
        const { data } = await axios.post(
          `${apiUrl}/gettotalpoints`, // Adjust the endpoint as needed
          { username: user },
          { withCredentials: true }
        );
        const { success, message, totalPoints } = data;
        if (success) {
          console.log("Success:", message);
          setTotalPoints(totalPoints);
        } else {
          console.log("Error:", message);
        }
      } catch (error) {
        console.log("An error occured while retrieving total points:", error);
      }
    };

    const verifyCookie = async () => {
      //this is redirecting to login page if there is no token
      const { data } = await axios.post(
        `${apiUrl}`,
        {},
        { withCredentials: true }
      );
      const { status, user } = data;
      setUsername(user);
      console.log("Username set to:", username);

      return status
        ? (toast(`Hello ${user}`, {
            position: "top-right",
          }),
          getTotalPoints(user))
        : (removeCookie("token"), navigate("/login"));
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  // User Interface Components
  return (
    <div className="thankyou_form">
      <div className="thankyouform_container">
        <h2 style={{ textAlign: "center", fontSize: "2rem" }}>
          Thank You, {username}!
        </h2>
        {/*<h2 style={{ textAlign: "center", fontSize: "2rem" }}>
          Total Score: {totPoints}
        </h2>*/}
        <h3 style={{ color: "#333", fontSize: "1.2rem" }}>
          Thank you so much for participating in our experiment! We appreciate
          your cooperation, as well as the time you donated to be a part of
          this. Your valuable participation will be used to accelerate our
          project lifecycle, have an amazing rest of your day!
        </h3>
        <div style={{ textAlign: "center" }}>
          <img
            src={
              "https://img.freepik.com/premium-vector/cute-pug-dog-waving-paw-cartoon-icon-vector-illustration_42750-875.jpg"
            }
            alt="Cute thank you pug"
            style={{ width: "500px", height: "500px" }}
          />
        </div>

        {/*<ToastContainer />*/}
      </div>
    </div>
  );
};

export default ThankYou;
