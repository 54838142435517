import { Route, Routes } from "react-router-dom";
import { Login, 
  Signup, 
  Game, 
  Home, 
  Game1, 
  Round, 
  Consent, 
  Quiz, 
  Instructions, 
  Video, 
  Demographics,
  ExperienceQuiz,
  PostExperiment,
  Screening,
  ThankYou,
  ThankYouPoint,
  ScreeningDisclaimer,
  PracticeRound,
  PracticeGame
} from "./pages";


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Consent />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/game" element={<Game />} />
        <Route path="/game1" element={<Game1 />} />
        <Route path="/round" element={<Round />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/instructions" element={<Instructions />} />
        <Route path="/video" element={<Video />} />
        <Route path="/demographics" element={<Demographics />} />
        <Route path="/experiencequiz" element={<ExperienceQuiz />} />
        <Route path="/postexperiment" element={<PostExperiment />} />
        <Route path="/screening" element={<Screening />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/thankyoupoint" element={<ThankYouPoint />} />
        <Route path="/screeningdisclaimer" element={<ScreeningDisclaimer />} />
        <Route path="/practiceround" element={<PracticeRound />} />
        <Route path="/practicegame" element={<PracticeGame />} />
      </Routes>
    </div>
  );
}

export default App;