import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "./Signup.css"; // Make sure you have this CSS for styling

const Signup = () => {
  const apiUrl = process.env.REACT_APP_API_URL || "https://mipha.cs.utep.edu";
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
    username: "",
  });

  useEffect(() => {
    const handlePopState = () => {
      window.history.go(1);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const { email, password, username } = inputValue;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  /*  const handleError = (err) =>
      toast.error(err, {
        position: "bottom-left",
      });
  */

  const handleSuccess = (m) => {
    /*toast.success(msg, {
      position: "bottom-left",
    });*/
    setTimeout(() => {
      navigate("/screeningdisclaimer"); // Adjust this as necessary
    }, 1000); // Delay for user experience
  };

  let date = new Date();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const additionDate = {
        createdDate: String(date.toLocaleDateString('en-US', { timeZone: 'America/Denver' })),
        createdTime: String(date.toLocaleTimeString('en-US', { timeZone: 'America/Denver' })),
      };
      const { data } = await axios.post(
        `${apiUrl}/signup`,
        {
          email, password, username,
          ...additionDate,
        },
        { withCredentials: true }
      );
      const { success, message } = data;
      if (success) {
        handleSuccess();
        console.log(message);
      } else {
        //handleError(message);
        console.log(message);
      }
    } catch (error) {
      console.error(error);
      if (error == "AxiosError: Request failed with status code 409") {
        alert("That username has been taken");
      } else if (username == "") {
        alert("No username has been provided");
      } else if (password == "") {
        alert("No password has been provided");
      } else if (email == "") {
        alert("No email has been provided");
      }
      //handleError("An error occurred during signup.");
    }
    setInputValue({ email: "", password: "", username: "" }); // Reset form fields
  };

  return (
    <div className="sign_up">
      <div className="form_container">
        <h2>Register</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              value={email}
              placeholder="Enter your email"
              onChange={handleOnChange}
            />
          </div>
          <div>
            <label htmlFor="username">Username</label>
            <input
              type="text"
              name="username"
              value={username}
              placeholder="Enter your username"
              onChange={handleOnChange}
            />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              value={password}
              placeholder="Enter your password"
              onChange={handleOnChange}
            />
          </div>
          <button type="submit" className="cont_button">
            Continue
          </button>
          {/*<span>
            Already have an account? <Link to={"/login"}>Login</Link>
          </span>*/}
        </form>
        {/*<ToastContainer />*/}
      </div>
    </div>
  );
};

export default Signup;
